import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GitHubButton from "react-github-btn";
import Link from "./link";
import "./styles/header.css";
import config from "../../config.js";
import Logo from "./images/Evinced_logo_light.svg";
import HelpIcon from "./images/help.svg";
import TwitterIcon from "./images/twitter.svg";
import Loadable from "react-loadable";
import LoadingProvider from "./mdxComponents/loading";

import Sidebar from "./sidebar";

function myFunction() {
    var x = document.getElementById("navbar");
    if (x.className === "topnav") {
        x.className += " responsive";
    } else {
        x.className = "topnav";
    }
}

const Header = ({ location }) => (
    <StaticQuery
        query={graphql`
            query headerTitleQuery {
                site {
                    siteMetadata {
                        headerTitle
                        githubUrl
                        helpUrl
                        tweetText
                        logo {
                            link
                            image
                        }
                        headerLinks {
                            link
                            text
                        }
                    }
                }
            }
        `}
        render={data => {
            const logoImg = require("./images/logo.svg");
            const twitter = require("./images/twitter.svg");
            const {
                site: {
                    siteMetadata: {
                        headerTitle,
                        githubUrl,
                        helpUrl,
                        tweetText,
                        logo,
                        headerLinks
                    }
                }
            } = data;
            const finalLogoLink = logo.link !== "" ? logo.link : "/";
            return (
                <header className={"navBarWrapper"}>
                    <div className={"navBarDefault"}>
                        <div className={"navBarHeader"}>
                            <Link
                                to={finalLogoLink}
                                className={"navBarBrand"}
                                aria-label="knowledge base home page"
                            >
                                <Logo />
                            </Link>
                            <div
                                className={"headerTitle displayInline"}
                                dangerouslySetInnerHTML={{
                                    __html: headerTitle
                                }}
                            />
                            <button
                                onClick={myFunction}
                                className={"navBarToggle"}
                            >
                                <span className={"iconBar"}></span>
                                <span className={"iconBar"}></span>
                                <span className={"iconBar"}></span>
                            </button>
                        </div>

                        <div id="navbar" className={"topnav"}>
                            <div className={"visibleMobile"}>
                                <Sidebar location={location} />
                            </div>
                        </div>
                    </div>

                    {helpUrl !== "" ? (
                        <a
                            href={helpUrl}
                            aria-label="How to use this Knowledge base?"
                        >
                            <HelpIcon />
                        </a>
                    ) : null}
                </header>
            );
        }}
    />
);

export default Header;
