import React from "react";
import ClosedSvg from "../images/closed";
import config from "../../../config";
import Link from "../link";

const TreeNode = ({
    className = "",
    setCollapsed,
    collapsed,
    url,
    title,
    items,
    ...rest
}) => {
    const isCollapsed = collapsed[url];
    const collapse = () => {
        setCollapsed(url);
    };
    const hasChildren = items.length !== 0;
    let location;
    if (typeof document != "undefined") {
        location = document.location;
    }
    const active =
        location &&
        (location.pathname.replace(/\/$/, "") === url ||
            location.pathname.replace(/\/$/, "") === config.gatsby.pathPrefix + url);
    
    const calculatedClassName = `${className} item ${active ? "active" : ""}`;

    return (
        <li className={`${calculatedClassName}`}>
            {!hasChildren && title && <Link to={url}>{title}</Link>}
            {!config.sidebar.frontLine && title && hasChildren ? (
                <button
                    onClick={collapse}
                    aria-expanded={isCollapsed ? "false" : "true"}
                    className={`collapser ${
                        isCollapsed ? "collapsed" : "expanded"
                    }`}
                >
                    {title}
                    <ClosedSvg />
                </button>
            ) : null}

            {!isCollapsed && hasChildren ? (
                <ul aria-label={`${title} entries`}>
                    {items.map(item => (
                        <TreeNode
                            key={item.url}
                            setCollapsed={setCollapsed}
                            collapsed={collapsed}
                            {...item}
                        />
                    ))}
                </ul>
            ) : null}
        </li>
    );
};
export default TreeNode;
