import React from "react";
import Tree from "./tree";
import { StaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { ExternalLink } from "react-feather";
import "../styles.css";
import "./sidebar.css";
import "./search.css";
import config from "../../../config";
import Loadable from "react-loadable";
import LoadingProvider from "../mdxComponents/loading";

const forcedNavOrder = config.sidebar.forcedNavOrder;

const LoadableComponent = Loadable({
    loader: () => import("../search/index"),
    loading: LoadingProvider
});

const isSearchEnabled =
    config.header.search && config.header.search.enabled ? true : false;

let searchIndices = [];
if (isSearchEnabled && config.header.search.indexName) {
    searchIndices.push({
        name: `${config.header.search.indexName}`,
        title: `Results`,
        hitComp: `PageHit`
    });
}

// eslint-disable-next-line no-unused-vars
const ListItem = styled(({ className, active, level, ...props }) => {
    return (
        <li className={className}>
            <a href={props.to} {...props} />
        </li>
    );
})`
    list-style: none;

    a {
        color: #5c6975;
        text-decoration: none;
        font-weight: ${({ level }) => (level === 0 ? 700 : 400)};
        padding: 0.45rem 0 0.45rem ${props => 2 + (props.level || 0) * 1}rem;
        display: block;
        position: relative;

        &:hover {
            color: var(--dark-green) !important;
        }

        ${props =>
            props.active &&
            `
      color: #663399;
      border-color: rgb(230,236,241) !important;
      border-style: solid none solid solid;
      border-width: 1px 0px 1px 1px;
      background-color: #fff;
    `} // external link icon
    svg {
            margin-right: 1rem;
        }
    }
`;

const Sidebar = styled("nav")`
    height: 100vh;
    overflow: auto;
    

    @media only screen and (max-width: 767px) {
        height: calc(100vh - 22rem);
        overflow-y: scroll;
        padding: 0 1rem;
    }
    @media only screen and (min-width: 1200px) {
        padding: 0 2.5rem 0 4.5rem;
    }
`;

const Divider = styled(props => (
    <li {...props} aria-hidden="true">
        <hr />
    </li>
))`
    list-style: none;
    padding: 0.5rem 0;

    hr {
        margin: 0;
        padding: 0;
        border: 0;
        border-bottom: 1px solid #ede7f3;
    }
    @media only screen and (max-width: 767px) {
        display: none;
    }
`;

const SidebarLayout = ({ location }) => (
    <StaticQuery
        query={graphql`
            query {
                allMdx {
                    edges {
                        node {
                            fields {
                                slug
                                title
                            }
                            frontmatter {
                                metaTitle
                                metaDescription
                                index
                              }
                        }
                    }
                }
            }
        `}
        render={({ allMdx }) => {
            return (
                <>
                    {isSearchEnabled ? (
                        <div className={"searchWrapper"}>
                            <LoadableComponent
                                collapse={true}
                                indices={searchIndices}
                                id="search-kb"
                            />
                        </div>
                    ) : null}
                    <Sidebar aria-label="Main">
                        <ul className={"sideBarUL"}>
                            <Tree edges={allMdx.edges} location={location} />
                            <Divider />
                            {config.sidebar.links.map((link, key) => {
                                if (link.link !== "" && link.text !== "") {
                                    return (
                                        <ListItem key={key} to={link.link}>
                                            {link.text}
                                            <ExternalLink size={14} />
                                        </ListItem>
                                    );
                                }
                            })}
                        </ul>
                    </Sidebar>
                </>
            );
        }}
    />
);

export default SidebarLayout;
