import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Link from "./link";
import "./styles/footer.css";
import config from "../../config.js";
import Logo from "./images/logo.svg";
import HelpIcon from "./images/help.svg";
import TwitterIcon from "./images/twitter.svg";

const help = require("./images/help.svg");

import Sidebar from "./sidebar";

const Footer = ({ location }) => (
    <StaticQuery
        query={graphql`
            query footerQuery {
                site {
                    siteMetadata {
                        tweetText
                        logo {
                            link
                            image
                        }
                        footerLinks {
                            link
                            text
                        }
                    }
                }
            }
        `}
        render={data => {
            const {
                site: {
                    siteMetadata: { tweetText, logo, footerLinks }
                }
            } = data;
            return (
                <footer className={"footerWrapper"}>
                    <nav aria-label="external links to evinced assets">
                        <ul className={"footer-nav"}>
                            {footerLinks.map((link, key) => {
                                if (link.link !== "" && link.text !== "") {
                                    return (
                                        <li key={key}>
                                            <a
                                                className="sidebarLink"
                                                href={link.link}
                                                target="_blank"
                                                rel="noopener"
                                                dangerouslySetInnerHTML={{
                                                    __html: link.text
                                                }}
                                            />
                                        </li>
                                    );
                                }
                            })}

                            {tweetText !== "" ? (
                                <li>
                                    <a
                                        href={
                                            "https://twitter.com/intent/tweet?&text=" +
                                            tweetText
                                        }
                                        target="_blank"
                                        rel="noopener"
                                        aria-label="share on twitter"
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={"shareIcon"}
                                        >
                                            <TwitterIcon />
                                        </span>
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                    </nav>
                </footer>
            );
        }}
    />
);

export default Footer;
