import React from "react";
import CodeBlock from "./codeBlock";
import AnchorTag from "./anchor";
import ImageTag from "./image";

import "../styles.css";

export default {
    h1: props => (
        <h1
            className="heading1"
            id={props.children.replace(/\s+/g, "").toLowerCase()}
            {...props}
        />
    ),
    h2: props => {
        let headingText = props.children.props
            ? props.children.props.children
            : props.children;
        return (
            <h2
                className="heading2"
                id={headingText.replace(/\s+/g, "").toLowerCase()}
                {...props}
            />
        );
    },
    h3: props => {
        let headingText = props.children.props
            ? props.children.props.children
            : props.children;
        if(Array.isArray(headingText)) {
            headingText = headingText[0];
        }
        return (
            <h3
                className="heading3"
                id={headingText.replace(/\s+/g, "").toLowerCase()}
                {...props}
            />
        );
    },
    h4: props => {
        let headingText = props.children.props
            ? props.children.props.children
            : props.children;
        return (
            <h4
                className="heading4"
                id={headingText.replace(/\s+/g, "").toLowerCase()}
                {...props}
            />
        );
    },
    h5: props => (
        <h5
            className="heading5"
            id={props.children.replace(/\s+/g, "").toLowerCase()}
            {...props}
        />
    ),
    h6: props => (
        <h6
            className="heading6"
            id={props.children.replace(/\s+/g, "").toLowerCase()}
            {...props}
        />
    ),
    p: props => <p className="paragraph" {...props} />,
    pre: props => <pre className="pre" {...props} />,
    code: CodeBlock,
    a: AnchorTag,
    img: ImageTag
    // TODO add `img`
    // TODO add `blockquote`
    // TODO add `ul`
    // TODO add `li`
    // TODO add `table`
};
