import React, { useState } from "react";
import config from "../../../config";
import TreeNode from "./treeNode";

const calculateTreeData = edges => {
  const filteredEdges =
    config.sidebar.ignoreSections.length > 0
      ? edges.filter(
          ({
            node: {
              fields: { slug }
            }
          }) => {
            const section = slug.split("/")[1];
            return !config.sidebar.ignoreSections.includes(section);
          }
        )
      : edges;

  const originalData = config.sidebar.ignoreIndex
    ? filteredEdges.filter(
        ({
          node: {
            fields: { slug }
          }
        }) => slug !== "/" && slug !== "/tbd"
      )
    : filteredEdges;

    originalData.sort(function(a, b) {
      var indexA = a.node.frontmatter.index,
      indexB = b.node.frontmatter.index;
      if (indexA < indexB) return -1;
      if (indexA > indexB) return 1;
      return 0;
    });

  const tree = originalData.reduce(
    (
      accu,
      {
        node: {
          fields: { slug, title }
        }
      }
    ) => {
      const parts = slug.split("/");
      let { items: prevItems } = accu;
      for (const part of parts.slice(1, -1)) {
        let tmp = prevItems.find(({ label }) => label == part);
        if (tmp) {
          if (!tmp.items) {
            tmp.items = [];
          }
        } else {
          tmp = { label: part, items: [] };
          prevItems.push(tmp);
        }
        prevItems = tmp.items;
      }
      const existingItem = prevItems.find(
        ({ label }) => label === parts[parts.length - 1]
      );
      if (existingItem) {
        existingItem.url = slug;
        existingItem.title = title;
      } else {
        prevItems.push({
          label: parts[parts.length - 1],
          url: slug,
          items: [],
          title
        });
      }
      return accu;
    },
    { items: [] }
  );
  const {
    sidebar: { forcedNavOrder = [] }
  } = config;
  const tmp = [...forcedNavOrder];
  tmp.reverse();
  return tmp.reduce((accu, slug) => {
    const parts = slug.split("/");
    let { items: prevItems } = accu;
    for (const part of parts.slice(1, -1)) {
      let tmp = prevItems.find(({ label }) => label == part);
      if (tmp) {
        if (!tmp.items) {
          tmp.items = [];
        }
      } else {
        tmp = { label: part, items: [] };
        prevItems.push(tmp);
      }
      prevItems = tmp.items;
    }
    // sort items alphabetically.
    // prevItems.map(item => {
    //   item.items = item.items.sort(function(a, b) {
    //     if (a.label < b.label) return -1;
    //     if (a.label > b.label) return 1;
    //     return 0;
    //   });
    // });
    const index = prevItems.findIndex(
      ({ label }) => label === parts[parts.length - 1]
    );
    accu.items.unshift(prevItems.splice(index, 1)[0]);
    return accu;
  }, tree);
};

const setCollapsedItems = rootTreeItem => {
  const defaultCollapsed = {};

  rootTreeItem.items.forEach(item => {
    if (
      config.sidebar.collapsedNav &&
      config.sidebar.collapsedNav.includes(item.url)
    ) {
      defaultCollapsed[item.url] = true;

      if (item.items.length > 0) {
        Object.assign(defaultCollapsed, setCollapsedItems(item));
      }
    } else if (item.items.length > 0) {
        item.items.forEach(topItem => {
              if (
                config.sidebar.collapsedNav &&
                config.sidebar.collapsedNav.includes(topItem.url)
              ) {
                defaultCollapsed[topItem.url] = true;
              }
        })
    } else {
      defaultCollapsed[item.url] = false;
    }
  });
  return defaultCollapsed;
};

const Tree = ({ edges }) => {
  const [treeData] = useState(() => {
    return calculateTreeData(edges);
  });

  const defaultCollapsed = setCollapsedItems(treeData);

  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const toggle = url => {
    setCollapsed({
      ...collapsed,
      [url]: !collapsed[url]
    });
  };
  return (
    <TreeNode
      className={`${
        config.sidebar.frontLine ? "showFrontLine" : "hideFrontLine"
      } firstLevel`}
      setCollapsed={toggle}
      collapsed={collapsed}
      {...treeData}
    />
  );
};

export default Tree;
