import React from "react";
import { MDXProvider } from "@mdx-js/react";
import ThemeProvider from "./themeProvider";
import mdxComponents from "./mdxComponents";
import Sidebar from "./sidebar";
import RightSidebar from "./rightSidebar";

import "./styles/layout.css";
const Layout = ({ children, location }) => (
  <ThemeProvider location={location}>
    <MDXProvider components={mdxComponents}>
      <div id="top-content-wrapper">
        <div className={"nav-left-bar-wrapper hiddenMobile"}>
          <div className="sidebar-inner">
            <Sidebar location={location} />
          </div>
        </div>
        <main>{children}</main>
        <div className={"toc-right-bar-wrapper hiddenMobile"}>
          <RightSidebar location={location} />
        </div>
      </div>
    </MDXProvider>
  </ThemeProvider>
);

export default Layout;
