const config = {
    gatsby: {
        pathPrefix: "/",
        siteUrl: "https://developer.evinced.com/",
        gaTrackingId: process.env.GA_TRACK_ID
        
    },
    header: {
        logo: "",
        logoLink: "/",
        title: "Developer Documentation",
        githubUrl: "",
        helpUrl: "",
        tweetText: "Evinced, Inc.'s documentation",
        links: [{ text: "", link: "" }],
        search: {
            enabled: true,
            indexName: "developer_docs",
            algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
            algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
            algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY
        }
    },
    footer: {
        links: [
            { text: "Knowledge Base", link: "https://knowledge.evinced.com/" },
            { text: "Blog", link: "https://www.evinced.com/blog/" },
            { text: "about us", link: "https://www.evinced.com/about" }
        ]
    },
    sidebar: {
        forcedNavOrder: [
            "/sdks-for-web-apps",
            "/sdks-for-mobile-apps"
        ],
        collapsedNav: [
            "/sdks-for-web-apps/release-notes",
            "/sdks-for-mobile-apps/release-notes",
            
        ],
        links: [
            /*{ text: "Evinced, Inc.", link: "https://evinced.com" },
            { text: "Blog", link: "https://evinced.com/blog" }*/
        ],
        frontline: false,
        ignoreIndex: true,
        ignoreSections: ["/tbd"]
    },
    siteMetadata: {
        title: "Documentation for developers | Evinced, Inc.",
        description:
            "Documentation and guides for using the Evinced SDKs",
        ogImage: "../images/og-image.png",
        docsLocation: "https://github.com/GetEvinced/evinced-kb",
        favicon: "../favicon/fav__e_16.png"
    },
    pwa: {
        enabled: false, // disabling this will also remove the existing service worker.
        manifest: {
            name: "Evinced Knowledge Base",
            short_name: "EvincedKB",
            start_url: "/",
            background_color: "#6b37bf",
            theme_color: "#6b37bf",
            display: "standalone",
            crossOrigin: "use-credentials",
            icons: [
                {
                    src: "src/pwa-512.png",
                    sizes: `512x512`,
                    type: `image/png`
                }
            ]
        }
    }
};

module.exports = config;
