import * as React from "react";
import styled from "styled-components";

import AttentionIcon from "./images/attention.svg";

const DisclamerWrapper = styled.div`
    border: 0.2rem solid #7846a7;
    border-radius: 0.2rem;
    box-shadow: 0rem 0.2rem 0.2rem #999;
    max-width: 60rem;
    margin: 5rem auto;
    padding: 2rem;

    svg {
        float: left;
        margin-right: 2rem;
        width: 3rem;

        path,
        circle {
            fill: #7846a7;
        }
    }

    p {
        font-size: 1.6rem;
        line-height: 1.5;

        &::after {
            clear: both;
            content: "";
            display: table;
        }
    }
`;

const DisclamerBox = ({ ...props }) => {
    if (props.children) {
        return (
            <DisclamerWrapper>
                <div>
                    <p>
                        <AttentionIcon />
                        {props.children}
                    </p>
                </div>
            </DisclamerWrapper>
        );
    } else {
        return null;
    }
};

export default DisclamerBox;
